<template>
  <div>
    <div class="h-full">
      <div class="rounded bg-white grid grid-cols-12 " :style="`height:` +  mainHeight + `px; !important`">
        <div class=" mx-2 shadow-md relative" :class="isFilterOpen ? 'box col-span-10' : 'box col-span-12'">
          <div class="bg-white cust_card p-2 heading-3 text-text1 justify-between flex">
            <p>FlexPetz.com - Accounting Overview</p>
            <div class="flex">
              <!-- <button  @click="openDrag()" style="letter-spacing: 1px;" class="px-2 mx-3 heading-6 flex items-center text-white bg-primary2 cursor-pointer rounded">
                  Reorder Column
                </button> -->
              <button @click="exportReport()" :class="firstLoading && reportListing.length > 0 ? 'text-white bg-primary2 cursor-pointer' : 'cursor-not-allowed bg-gray-300 tet-text2 filter opacity-60'" style="letter-spacing: 1px;" class="px-2 cust_card heading-6 mr-8 flex items-center">
                Export to Excel
              </button>
            </div>
            <div class="top-0 absolute" :class="isFilterOpen ? '-right-4' : 'transition-all delay-75 -right-2'" >
              <button id="toggle" class="bg-gray-600 hover:bg-gray-700 transition delay-75 w-11 h-11 rounded-l-full " @click="openCloseFilter()">
                <span v-if="isFilterOpen"><i class="fas fa-caret-right h-6 w-6 text-white mt-1"></i></span>
                <p v-if="!isFilterOpen"><i class="fas fa-caret-left h-6 w-6 text-white mt-1"></i></p>
              </button>
            </div>
          </div>
          <div style="overflow-y: auto; white-space: nowrap;" class="table_containder relative" v-if="reportListing.length > 0 && firstLoading">
            <div class="text-text2  min-w-min heaer_row border p-2 border-gray1 font-bold flex heading-6 py-1 items-center" style="position: sticky;top: 0;background: white;">
              <div class="small6_col  flex items-center">
                <span class="cursor-pointer overFlowParaA" @click="sortByColumn(0, titleObj[0].sort, titleObj[0].value)">{{titleObj[0].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[0].value) && titleObj[0].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[0].value) && !titleObj[0].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small3_col flex items-center " style="display: flex;">
                <span class="cursor-pointer overFlowParaA" @click="sortByColumn(1, titleObj[1].sort, titleObj[1].value)">{{titleObj[1].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[1].value) && titleObj[1].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[1].value) && !titleObj[1].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small2_col  flex items-center">
                <span class="cursor-pointer overFlowParaA" @click="sortByColumn(2, titleObj[2].sort, titleObj[2].value)">{{titleObj[2].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[2].value) && titleObj[2].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[2].value) && !titleObj[2].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small2_col  flex items-center">
                <span class="cursor-pointer overFlowParaA" @click="sortByColumn(3, titleObj[3].sort, titleObj[3].value)">{{titleObj[3].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[3].value) && titleObj[3].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[3].value) && !titleObj[3].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small2_col  flex items-center">
                <span class="cursor-pointer overFlowParaA" @click="sortByColumn(4, titleObj[4].sort, titleObj[4].value)">{{titleObj[4].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[4].value) && titleObj[4].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[4].value) && !titleObj[4].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small3_col  flex items-center">
                <span class="cursor-pointer overFlowParaA" @click="sortByColumn(5, titleObj[5].sort, titleObj[5].value)">{{titleObj[5].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[5].value) && titleObj[5].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[5].value) && !titleObj[5].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small4_col  flex items-center ">
                <span class="cursor-pointer overFlowParaA" @click="sortByColumn( 6, titleObj[6].sort, titleObj[6].value)">{{titleObj[6].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[6].value) && titleObj[6].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[6].value) && !titleObj[6].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small5_col  flex items-center ">
                <span class="cursor-pointer overFlowParaA" @click="sortByColumn(7, titleObj[7].sort, titleObj[7].value)">{{titleObj[7].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[7].value) && titleObj[7].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[7].value) && !titleObj[7].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small4_col  flex items-center ">
                <span class="cursor-pointer overFlowParaA" @click="sortByColumn( 8, titleObj[8].sort, titleObj[8].value)">{{titleObj[8].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[8].value) && titleObj[8].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[8].value) && !titleObj[8].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small4_col  flex items-center ">
                <span class="cursor-pointer overFlowParaA" @click="sortByColumn(9, titleObj[9].sort, titleObj[9].value)">{{titleObj[9].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[9].value) && titleObj[9].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[9].value) && !titleObj[9].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small2_col  flex items-center ">
                <span class="cursor-pointer overFlowParaA" @click="sortByColumn(10, titleObj[10].sort, titleObj[10].value)">{{titleObj[10].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[10].value) && titleObj[10].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[10].value) && !titleObj[10].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small4_col  flex items-center  ">
                <span class="cursor-pointer overFlowParaA" @click="sortByColumn(11, titleObj[11].sort, titleObj[11].value)">{{titleObj[11].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[11].value) && titleObj[11].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[11].value) && !titleObj[11].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small3_col  flex items-center ">
                <span class="cursor-pointer overFlowParaA" @click="sortByColumn( 12, titleObj[12].sort, titleObj[12].value)">{{titleObj[12].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[12].value) && titleObj[12].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[12].value) && !titleObj[12].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small3_col  flex items-center ">
                <span class=" overFlowParaA" >{{titleObj[13].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[13].value) && titleObj[13].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[13].value) && !titleObj[13].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small3_col  flex items-center rightText">
                <span class=" overFlowParaA" >{{titleObj[14].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[14].value) && titleObj[14].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[14].value) && !titleObj[14].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small1_col  flex items-center rightText">
                <span class=" overFlowParaA">{{titleObj[15].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[15].value) && titleObj[15].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[15].value) && !titleObj[15].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small3_col  flex items-center rightText">
                <span class=" overFlowParaA" >{{titleObj[16].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[16].value) && titleObj[16].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[16].value) && !titleObj[16].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small4_col  flex items-center rightText">
                <span class=" overFlowParaA" >{{titleObj[17].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[17].value) && titleObj[17].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[17].value) && !titleObj[17].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small3_col  flex items-center rightText">
                <span class=" overFlowParaA" >{{titleObj[18].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[18].value) && titleObj[18].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[18].value) && !titleObj[18].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small4_col  flex items-center rightText">
                <span class=" overFlowParaA" >{{titleObj[19].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[19].value) && titleObj[19].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[19].value) && !titleObj[19].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small1_col  flex items-center rightText">
                <span class=" overFlowParaA" >{{titleObj[20].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[20].value) && titleObj[20].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[20].value) && !titleObj[20].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
            </div>
            <div :style="`max-height:` +  cardHight + `px; !important`" class="">
              <div class="min-w-min flex border-r border-l border-b p-2 border-gray1 bg-white text-sm py-1 cursor-pointer hover:bg-gray-50 items-start" v-for="(value, indexA) in reportListing" :key="indexA">
                <div class="small6_col">
                  <span class="heading-6-1 text-text1">{{value.entityId}}</span>
                </div>
                <div class="small3_col">
                  <span class="heading-6 text-text1">{{value.createdAt | dateTimeFilter}}</span>
                </div>
                <div class="small2_col">
                  <span class="heading-6 text-text1">{{value.updatedAt | dateTimeFilter}}</span>
                </div>
                <div class="small2_col">
                  <span class="heading-6 text-text1">{{value.customerFirstname}}</span>
                </div>
                <div class="small2_col">
                  <span class="heading-6 text-text1">{{value.customerLastname}}</span>
                </div>
                <div class="small3_col">
                  <span class="heading-6 text-text1">{{value.customerEmail}}</span>
                </div>
                <div class="small4_col overFlowParaA">
                  <span class="heading-6-1 text-text1">{{value.street}}</span>
                </div>
                <div class="small5_col ">
                  <span class="heading-6-1 text-text1">{{value.city}}</span>
                </div>
                <div class="small4_col overFlowParaA">
                  <span class="heading-6-1 text-text1">{{value.region}}</span>
                </div>
                <div class="small4_col overFlowParaA">
                  <span class="heading-6-1 text-text1">{{value.postcode}}</span>
                </div>
                <div class="small2_col overFlowParaA">
                  <span class="heading-6-1 text-text1">{{value.shippingMethod}}</span>
                </div>
                <div class="small4_col ">
                  <span class="heading-6-1 text-text1">{{value.status1}}</span>
                </div>
                <div class="small3_col ">
                  <span class="heading-6-1 text-text1">{{value.status2}}</span>
                </div>
                <div>
                  <div v-for="(data, indexB) in value.amountDetail" :key="indexB" class="flex py-0.5">
                    <div class="small3_col ">
                      <span class="heading-6-1 text-text1">{{data.orderComment}}</span>
                    </div>
                    <div class="small3_col rightText">
                      <span class="heading-6-1 text-text1">{{data.shippingAmount | amountFormaterWithToFix}}</span>
                    </div>
                    <div class="small1_col rightText ">
                      <span class="heading-6-1 text-text1">{{data.baseShippingAmount | amountFormaterWithToFix}}</span>
                    </div>
                    <div class="small3_col rightText">
                      <span class="heading-6-1 text-text1">{{data.taxAmount | amountFormaterWithToFix}}</span>
                    </div>
                    <div class="small4_col rightText">
                      <span class="heading-6-1 text-text1">{{data.subtotal | amountFormaterWithToFix}}</span>
                    </div>
                    <div class="small3_col rightText">
                      <span class="heading-6-1 text-text1">{{data.revenueVoided | amountFormaterWithToFix}}</span>
                    </div>
                    <div class="small4_col rightText">
                      <span class="heading-6-1 text-text1">{{data.totalPaid | amountFormaterWithToFix}}</span>
                    </div>
                    <div class="small1_col rightText">
                      <span class="heading-6-1 text-text1">{{data.totalQtyOrdered}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="layout m-2" v-if="reportListing.length === 0 && firstLoading">
            <div class="flex p-3 text-sm text-primary2 bg-white border-blue-100 border-2 rounded-lg  items-center" role="alert">
              <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
              <span class="sr-only">Info</span>
              <div>
                <p class=" font-medium heading-4">No record found.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="box col-span-2 overflow-y-auto mx-2 shadow-md transition-transform delay-300 ease-in-out transition-2" :style="!isFilterOpen ? 'display: none' : ''">
          <GlobalFilter :callFrom="'FlexPetz.com'" :callParent="'accounting'"  @closeFilter="closeFilter" :isFilterOpen="isFilterOpen"/>
        </div>
      </div>
    </div>
    <div v-if="deleteConfirm" class="popup_overlay">
      <div style="width: 90%;" class="custom_dialog">
        <div class="text-text1 px-4 py-3 flex justify-between items-center">
          <span class="heading-3">Change Column display order</span>
          <div class="flex justify-end">
            <button class="bg-secondary text-white shadow mr-2 rounded-full px-4 py-1.5" style="letter-spacing: 1px;" @click="deleteConfirm = false;resetColumn()">Reset</button>
            <button class="bg-primary2 text-white shadow mr-2 rounded-full px-4 py-1.5" @click="deleteConfirm = false;saveToLocal()"><i class="fas fa-times h-5 w-5 text-white pt-1"></i></button>
          </div>
        </div>
        <hr>
        <div class="p-2 overflow-auto" style="max-height: 970px;">
          <span class="heading-4 text-text1">● Drag chip left/right to change Column display order</span>
          <draggable class="flex mt-4" :list="titleObj" group="people" @start="drag = true" @end="dragArray('false')" id='list'>
            <div v-for="(data, index) in titleObj" :key="index" class="bg-white text-sm p-1 items-center">
                <div class="flex items-center">
                  <button class="border border-gray2 rounded-lg py-2 px-3" style="cursor: grab;">{{data.title}}</button>
                </div>
              </div>
            </draggable>
          </div>
        </div>
      </div>
</div>
</template>
<script>
import draggable from 'vuedraggable'
import axios from 'axios'
import {downloadFile} from '@/utils/common.js'
import moment from 'moment'
import API from '@/api/App.js'
import GlobalFilter from '@/View/Admin/Components/filterWithoutSelection.vue'
// import Pagination from '@/View/components/pagination.vue'
export default {
  components: {
    draggable,
    GlobalFilter,
  },
  data () {
    return {
      deleteConfirm: false,
      isFilterOpen: true,
      reportListing: [],
      titleObj: [
        {title: 'Entity Id', icon: '', value: 'entityId', sort: false},
        {title: 'Created At(EST)', icon: '', value: 'createdAt', sort: true},
        {title: 'Updated Date(EST)', icon: '', value: 'updatedAt', sort: true},
        {title: 'Customer First Name', icon: '', value: 'customerFirstname', sort: true},
        {title: 'Customer Last Name', icon: '', value: 'customerLastname', sort: true},
        {title: 'Customer Email', icon: '', value: 'customerEmail', sort: true},
        {title: 'Address Street', icon: '', value: 'street', sort: true},
        {title: 'Address City', icon: '', value: 'city', sort: true},
        {title: 'Address Region', icon: '', value: 'region', sort: true},
        {title: 'Address Postcode', icon: '', value: 'postcode', sort: true},
        {title: 'Shipping Method', icon: '', value: 'shippingMethod', sort: true},
        {title: 'Status', icon: '', value: 'status1', sort: true},
        {title: 'Status 2', icon: '', value: 'status2', sort: true},
        {title: 'Order Comment', icon: '', value: 'orderComment', sort: true},
        {title: 'Revenue Shipping', icon: '', value: 'shippingAmount', sort: true},
        {title: 'Base Shipping Amount', icon: '', value: 'baseShippingAmount', sort: true},
        {title: 'Sales Tax Payable', icon: '', value: 'taxAmount', sort: true},
        {title: 'Revenue', icon: '', value: 'subtotal', sort: true},
        {title: 'Revenue-(Voided)', icon: '', value: 'revenueVoided', sort: true},
        {title: 'Total Paid', icon: '', value: 'totalPaid', sort: true},
        {title: 'Total Quantity Ordered', icon: '', value: 'totalQtyOrdered', sort: true},
      ],
      pagination: {
        sortBy: '',
        descending: true
      },
      filterObject: {
        startDate: '',
        endDate: '',
        sendItemId: [],
        minDate: '',
        maxDate: '',
      },
      cardHight: 0,
      mainHeight: 0,
      defaultStartDate: '',
      defaultEndDate: '',
      showCustomDatepicker: false,
      firstLoading: false,
    }
  },
  created() {
  },
  beforeMount () {
  },
  mounted () {
    this.cardHight = window.innerHeight - 230
    this.mainHeight = window.innerHeight - 140
    console.log('this.cardHight', this.cardHight, this.mainHeight)
    this.defaultEndDate = new Date()
    let currentDate = new Date()
    this.defaultStartDate = new Date(currentDate.setDate(currentDate.getDate() - 29))
    this.filterObject.startDate = this.defaultStartDate
    this.filterObject.endDate = this.defaultEndDate
    console.log('this.defaultStartDate', this.defaultStartDate)
    console.log('this.defaultEndDate', this.defaultEndDate)
  },
  watch: {
    filterObject: {
      handler () {
        console.log('filterObject', this.filterObject)
      },
      deep: true
    },
  },
  methods: {
    resetColumn () {
      this.dragArray('reset')
    },
    saveToLocal () {
      localStorage.setItem('accountingFlexpetz', JSON.stringify(this.titleObj))
    },
    // openDrag () {
    //   this.deleteConfirm = true
    //   console.log('click')
    // },
    openCloseFilter () {
      if (this.isFilterOpen) {
        this.isFilterOpen = false
      } else {
        this.isFilterOpen = true
      }
      console.log('openCloseFilter')
    },
    customDateListener (data) {
      console.log('datadatadatadatadatadata', data)
      this.showCustomDatepicker = false
      if (data !== null) {
        this.filterObject.startDate = data.startDate
        this.filterObject.endDate = data.endDate
      }
    },
    closeFilter (data) {
      console.log('data', data)
      this.filterObject.startDate = data.startDate
      this.filterObject.endDate = data.endDate
      this.getListing()
    },
    getListing () {
      this.firstLoading = false
      console.log('this.filterObject.startDate 123 123', this.filterObject.startDate)
      console.log('this.filterObject.endDate 123 123', this.filterObject.endDate)
      let utcSTART = moment(this.filterObject.startDate).format("YYYY-MM-DD");
      let utcEND = moment(this.filterObject.endDate).format("YYYY-MM-DD");
      this.$store.dispatch('SetLoader', {status: true, message: 'Please wait, it takes some time ...'})
      API.flexpetzAccountingReportListing(
        utcSTART,
        utcEND,
        false,
        response => {
          this.reportListing = response.Data === null ? [] : response.Data
          console.log(response.Data)
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.firstLoading = true
        },
        error => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    sortByColumn (i, val1, val2) {
      console.log('i', i)
      console.log('val1', val1)
      console.log('val2', val2)
      this.titleObj[i].sort = !val1
      this.pagination.descending = val1
      this.pagination.sortBy = val2
      console.log('this.pagination.descending', this.pagination.descending)
      if (this.pagination.descending) {
        console.log('click 1')
        this.reportListing.sort(function (a, b) {
          console.log('a', a)
          console.log('b', b)
          if (val2 === 'entityId') {
            var nameA = a.entityId
            var nameB = b.entityId
          } else if (val2 === 'createdAt') {
            nameA = a.createdAt
            nameB = b.createdAt
          } else if (val2 === 'updatedAt') {
            nameA = a.updatedAt
            nameB = b.updatedAt
          } else if (val2 === 'customerFirstname') {
            nameA = a.customerFirstname
            nameB = b.customerFirstname
          } else if (val2 === 'customerLastname') {
            nameA = a.customerLastname
            nameB = b.customerLastname
          } else if (val2 === 'customerEmail') {
            nameA = a.customerEmail
            nameB = b.customerEmail
          } else if (val2 === 'street') {
            nameA = a.street
            nameB = b.street
          } else if (val2 === 'city') {
            nameA = a.city
            nameB = b.city
          } else if (val2 === 'region') {
            nameA = a.region
            nameB = b.region
          } else if (val2 === 'postcode') {
            nameA = a.postcode
            nameB = b.postcode
          } else if (val2 === 'shippingMethod') {
            nameA = a.shippingMethod
            nameB = b.shippingMethod
          } else if (val2 === 'status1') {
            nameA = a.status1
            nameB = b.status1
          } else if (val2 === 'status2') {
            nameA = a.status2
            nameB = b.status2
          }
          console.log('nameA-------------------- 1', nameA)
          console.log('nameB-------------------- 1', nameB)
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
      } else {
        console.log('click 2')
        this.reportListing.reverse(function (a, b) {
          if (val2 === 'entityId') {
            var nameA = a.entityId
            var nameB = b.entityId
          } else if (val2 === 'createdAt') {
            nameA = a.createdAt
            nameB = b.createdAt
          } else if (val2 === 'updatedAt') {
            nameA = a.updatedAt
            nameB = b.updatedAt
          } else if (val2 === 'customerFirstname') {
            nameA = a.customerFirstname
            nameB = b.customerFirstname
          } else if (val2 === 'customerLastname') {
            nameA = a.customerLastname
            nameB = b.customerLastname
          } else if (val2 === 'customerEmail') {
            nameA = a.customerEmail
            nameB = b.customerEmail
          } else if (val2 === 'street') {
            nameA = a.street
            nameB = b.street
          } else if (val2 === 'city') {
            nameA = a.city
            nameB = b.city
          } else if (val2 === 'region') {
            nameA = a.region
            nameB = b.region
          } else if (val2 === 'postcode') {
            nameA = a.postcode
            nameB = b.postcode
          } else if (val2 === 'shippingMethod') {
            nameA = a.shippingMethod
            nameB = b.shippingMethod
          } else if (val2 === 'status1') {
            nameA = a.status1
            nameB = b.status1
          } else if (val2 === 'status2') {
            nameA = a.status2
            nameB = b.status2
          }
          console.log('nameA-------------------- 2', nameA)
          console.log('nameB-------------------- 2', nameB)
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
      }
      this.titleObj[i].isDesc = !this.titleObj[i].isDesc
    },
    async exportReport () {
      if (this.firstLoading && this.reportListing.length > 0) {
        let utcSTART = moment(this.filterObject.startDate).format("YYYY-MM-DD");
        let utcEND = moment(this.filterObject.endDate).format("YYYY-MM-DD");
        if (this.filterObject.sendItemId.length === 0) {
          this.filterObject.sendItemId = []
        }
        let day1 = moment(this.filterObject.startDate).format("DD-MM-YY")
        let day2 = moment(this.filterObject.endDate).format("DD-MM-YY")
        let Time = 'FlexPetz Accounting Report-' + day1 + '-To-' + day2
        let data = new FormData()
        data.append('from', utcSTART)
        data.append('to', utcEND)
        data.append('export', true)
        await this.downloadAttachment('api/v1/analytics/flexPet/accountingOverview', `${Time}` + '.xlsx', data)
      }
    },
    async downloadAttachment (subUrl, fileName, formData) {
      let url = this.$store.getters.liveServerUrl + subUrl
      // If Want To cancel request
      const cancelToken = axios.CancelToken
      const source = cancelToken.source()
      let originalFileName = fileName
      this.$store.dispatch('CancelDownloadRequest', source)
      // Show loader while downloading
      this.$store.dispatch('SetDownloadLoader', true)
      // Calling Api and waiting for response
      let response = await downloadFile(formData, url, source)
      // Checking for status if 200 == success
      if (response.status === 200) {
        this.$store.dispatch('CancelDownloadRequest', null)
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(new Blob([response.data]))
        console.log(window.URL.createObjectURL(new Blob([response.data])))
        link.setAttribute('download', originalFileName)
        document.body.appendChild(link)
        link.click()
        // CLosing loader and reseting text in loader
        this.$store.dispatch('SetDownloadLoader', false)
        this.$store.dispatch('SetDownloadMessage', '')
      } else {
        this.$store.dispatch('CancelDownloadRequest', null)
        this.$store.dispatch('SetDownloadMessage', '')
        this.$store.dispatch('SetDownloadLoader', false)
      }
    },
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>

.rightText {
  text-align: right !important;
  justify-content: right !important;
}
.small1_col {
  min-width: 180px !important;
  width: 180px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}

.small2_col {
  min-width: 155px !important;
  width: 155px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.small11_col {
  min-width: 192px !important;
  width: 192px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.small3_col {
  min-width: 130px !important;
  width: 130px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.small4_col {
  min-width: 110px !important;
  width: 110px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.small5_col {
  min-width: 95px !important;
  width: 95px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.small6_col {
  min-width: 80px !important;
  width: 80px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.overFlowParaA {
  word-break: break-all !important;
  white-space: initial !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block;
}

</style>
